// eslint-disable-next-line
import React, { useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Common from "../../layouts/Common";
import Carousel from "react-bootstrap/Carousel";
import Form from "react-bootstrap/Form";
import { NavLink } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { FaArrowRight } from "react-icons/fa";
import muktodhara from "../../assets/images/muktodhara.jpeg";
import challenger from "../../assets/images/challenger.png";
import haidory_trade from "../../assets/images/haidory_trade.jpeg";
import kaniz_travels from "../../assets/images/kaniz_travels.png";
import { FaArrowAltCircleRight } from "react-icons/fa";
import spring from "../../assets/images/spring-boot-icon.png";
import hire from "../../assets/images/ERP.png";
import angular from "../../assets/images/ani.svg";
import reacjs from "../../assets/images/reacjs.svg";
import pay from "../../assets/images/pay.svg";
import tech from "../../assets/images/tech.svg";
import java from "../../assets/images/java.png";
import php from "../../assets/images/php.svg";
import node from "../../assets/images/node.svg";
import lara from "../../assets/images/lara.svg";
import mong from "../../assets/images/mong.svg";
import soft from "../../assets/images/soft.svg";
import appd from "../../assets/images/appd.svg";
import carta from "../../assets/images/carta.svg";
import codin from "../../assets/images/software-qa-services.svg";
import ios from "../../assets/images/ios.svg";
import avater from "../../assets/images/avater.svg";
import awslogo from "../../assets/images/awslogo.png";
import googlepartner from "../../assets/images/googlepartner.png";
import mslogo from "../../assets/images/mslogo.jpg";

import projectc from "../../assets/images/projectc.svg";
import brain from "../../assets/images/brain.svg";
import countires from "../../assets/images/countires.svg";
import experience from "../../assets/images/experience.svg";
import partners from "../../assets/images/partners.svg";

import bootstrap from "../../assets/images/bootstrap.svg";
import ember from "../../assets/images/ember.svg";
import cmc from "../../assets/images/cmc.jpeg";
import cmcec from "../../assets/images/cemeclogo.png";
import alanaji from "../../assets/images/alanaji.jpg";
import consult from "../../assets/images/Swift_Consultancy.png";
import softland from "../../assets/images/Softland_new.png";
import haidory from "../../assets/images/haidory_new.png";
import marscons from "../../assets/images/marsCons.png";
import pacific from "../../assets/images/pacific.jpg";
import manufacturing from "../../assets/images/manufacturing.svg";
import logistics from "../../assets/images/logistics.svg";
import healthcare from "../../assets/images/healthcare.svg";
import aviation from "../../assets/images/aviation.svg";
import ecommerce from "../../assets/images/ecommerce.svg";
import content from "../../assets/images/aws-service-pic.png";
import content1 from "../../assets/images/content.svg";
import wp from "../../assets/images/wp.svg";
import as from "../../assets/images/as.svg";
import bs from "../../assets/images/bs.svg";
import about from "../../assets/images/about.svg";
import leftimg from "../../assets/images/leftimg.svg";
import rightimfe from "../../assets/images/rightimfe.svg";
import client from "../../assets/images/client.svg";
import sof1 from "../../assets/images/ui-service-pic.png";

// import getques from '../../assets/images/getques.svg';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import FreeQuote from "../FreeQuote";
import Modal from "react-bootstrap/Modal";

const Home = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const techSectionRef = useRef(null);
  // const scrollOffset = 100;
  // const scrollToSection = () => {
  //   const currentPosition =
  //     techSectionRef.current.getBoundingClientRect().top + window.scrollY;
  //   window.scrollTo({
  //     top: currentPosition - scrollOffset,
  //     behavior: "smooth",
  //   });
  // };
  // const scrollToSection = () => {
  //   if (techSectionRef.current) {
  //     techSectionRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
  };

  const options2 = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  const options3 = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 2,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  return (
    <Common>
      <section className="banner-section">
        <Container>
          <Row>
            <Col md={12}>
              <div className="slider-banner slide-arrow">
                {/* fade */}
                <Carousel>
                  <Carousel.Item>
                    <h1 className="text-white">
                      <strong>Robust Software Solutions & IT Consulting</strong>
                    </h1>
                    <p className="text-white p20">
                      Swift IT Ltd. is a leading software development company
                      with office in <br />
                      Bangladesh. Our deep understanding about the
                      <br />
                      software outsourcing and IT consulting makes us a unique
                      solution provider.
                    </p>
                    {/* <NavLink
                      exact
                      to="/hire-dedicated-software-developers-programmers"
                    >
                      <Button variant="primary btn-custom mt-3 btn-big">
                        Read More
                        <FaArrowRight />
                      </Button>
                    </NavLink> */}
                    {/* <section className="service-slider-block">
                <div className="block-item"> <span>
                  <FaCalendarAlt/>
                  </span>
                  <p className="text-white">Time Zone Advantages</p>
                </div>
                <div className="block-item"> <span>
                  <FaDesktop/>
                  </span>
                  <p className="text-white">Latest Technology</p>
                </div>
                <div className="block-item"> <span>
                  <FaMoneyCheckAlt/>
                  </span>
                  <p className="text-white">Cost Effective</p>
                </div>
                <div className="block-item"> <span>
                  <FaDelicious/>
                  </span>
                  <p className="text-white">Strict NDA Terms</p>
                </div>
              </section> */}
                  </Carousel.Item>
                  {/* <Carousel.Item>
                    <h1 className="text-white">
                      <strong>IT Consulting</strong>
                    </h1>
                    <p className="text-white p20">
                      As a leading IT consulting firm, we help businesses to
                      reinvent and <br />
                      excel by establishing complete relianceon information
                      technology and <br />
                      offering high quality technology consulting services.{" "}
                    </p>
                  </Carousel.Item> */}
                </Carousel>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="all-company-feature">
        <Container>
          <Row>
            <Col md={12} className="text-center pt-3">
              {/* <h4 className="mt-5">SERVICES</h4> */}
              <h2 className="mt-5 custom-head">OUR SERVICES</h2>
            </Col>
          </Row>
          <Row>
            <Col lg={4} sm={12}>
              <div className="company-feature">
                {" "}
                <span
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={hire} alt="hire" className="p-1" />
                </span>
                <h5 className="text-center mt-5">ERP Software Development</h5>
                <p>
                  {" "}
                  Looking for trusted experienced and professional services?
                  Take our ERP services or hire developers from us for your
                  development.{" "}
                </p>
                <ul className="ul-feature">
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>ERP Software Development Services</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>Hire Dedicated Developers</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>QA/Testing Services</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>IT Consulting</p>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature">
                {" "}
                <span
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={soft} alt="soft" />
                </span>
                <h5 className="text-center mt-5">CRM Software Development</h5>
                <p>
                  {" "}
                  Incorporate the latest of interactivity, multimedia and user
                  interface on your CRM Software with our services. You can get
                  ongoing support to evolve your product.
                </p>
                <ul className="ul-feature">
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>CRM Software Development Services</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>Hire Professional Developers</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>QA/Testing Services</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>IT Consulting</p>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature">
                {" "}
                <span
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={sof1} alt="sof1" className="p-1" />
                </span>
                <h5 className="text-center mt-5">
                  Website Design & Development
                </h5>
                <p>
                  {" "}
                  Get custom website design and development services from us,
                  incorporate as many features you imagine and we will
                  incorporate them for you.{" "}
                </p>
                <ul className="ul-feature">
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>Full Stack Web Development</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>Front-End Development</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>Back-End Development</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>Database Management</p>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature">
                {" "}
                <span
                  data-aos="fade-right"
                  data-aos-offset="320"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={codin} alt="codin" />
                </span>
                <h5 className="text-center mt-5">Digital Marketing Solution</h5>
                <p>
                  We specialize in driving brand visibility, engagement, and
                  growth through data-driven strategies in SEO, social media
                  marketing, pay-per-click (PPC) advertising, email campaigns,
                  and content marketing.
                </p>
                <ul className="ul-feature">
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p> Content Marketing</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>Social Media Marketing</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>Search engine marketing (SEM)</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>Email marketing</p>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature">
                {" "}
                <span
                  data-aos="fade-right"
                  data-aos-offset="320"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={content1} alt="content1" />
                </span>
                <h5 className="text-center mt-5">Hardware Supply Service</h5>
                <p>
                  We provide a comprehensive range of high-quality hardware
                  solutions, including servers, workstations, networking
                  equipment, and peripherals, to meet the unique needs of your
                  business.
                </p>
                <ul className="ul-feature">
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>Customized Hardware Packages</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>Reliable Delivery & Installation</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p>Long-Term Support & Warranty</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p> Expert Hardware Consultation</p>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature">
                {" "}
                <span
                  data-aos="fade-right"
                  data-aos-offset="320"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={content} alt="content" className="p-1" />
                </span>
                <h5 className="text-center mt-5">Networking Solution</h5>
                <p>
                  We deliver promising management of high-performance networks
                  tailored to your business needs, ensuring seamless
                  connectivity, robust security, and future-ready scalability.
                </p>
                <ul className="ul-feature">
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p> High-Performance Connectivity</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p> End-to-End Security</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p> Scalable Solutions for Growth</p>
                  </li>
                  <li>
                    <FaArrowAltCircleRight className="icon-list" />
                    <p> 24/7 Monitoring & Support</p>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12} className="text-center mt-4">
              <Button
                variant="primary btn-custom rounded mt-3 btn-big w260"
                onClick={handleShow}
              >
                Get a Free Quote
                <FaArrowRight className="ms-5" />
              </Button>
            </Col>
          </Row>
        </Container>

        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="md"
        >
          <Modal.Header closeButton className="border-bottom-0"></Modal.Header>
          <Modal.Body>
            <FreeQuote />
          </Modal.Body>
        </Modal>
      </section>
      <section
        className=" frame-bg mtp"
        id="technologies"
        // ref={techSectionRef}
      >
        <Container>
          <Row>
            <Col md={12} className="text-center">
              <h2 className="custom-head">OUR CLIENTS</h2>
              {/* <h2>Technologies</h2> */}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ul className="ul-tech-list-new ">
                <li data-aos="fade-right">
                  {" "}
                  <span className="mt-2">
                    <img src={cmc} alt="cmc" />
                  </span>
                  <p>Cumilla Medical Center (Pvt) Ltd.</p>
                </li>
                <li data-aos="fade-right">
                  <span className="mt-2">
                    <img src={pacific} alt="pacific" />
                  </span>
                  <p>Pacific Academy</p>
                </li>
                <li data-aos="fade-right" className="">
                  {" "}
                  <span className="mt-2">
                    <img src={cmcec} alt="cmec" />
                  </span>
                  <p>Central Medical Collage and Hospital</p>
                </li>
                <li data-aos="fade-right" className="border-end-0">
                  {" "}
                  <span className="mt-2">
                    <img src={marscons} alt="marscons" />
                  </span>
                  <p>Mars Constech Ltd.</p>
                </li>
                {/* <li data-aos="fade-right" className="">
                  {" "}
                  <span>
                    <img src={spring} alt="spring" />
                  </span>
                  <p>Muktodhara Green Park</p>
                </li> */}
                <li data-aos="fade-right" className="">
                  {" "}
                  <span className="mt-2">
                    <img src={haidory} alt="haidory" />
                  </span>
                  <p>Haidory Group</p>
                </li>

                <li data-aos="fade-left" className="">
                  {" "}
                  <span className="mt-2">
                    <img src={softland} alt="softland" />
                  </span>
                  <p>Softland Construction Ltd.</p>
                </li>

                <li data-aos="fade-left" className="">
                  {" "}
                  <span className="mt-2">
                    <img src={consult} alt="consult" />
                  </span>
                  <p>Swift Consultancy Ltd.</p>
                </li>
                <li data-aos="fade-left" className=" border-end-0">
                  {" "}
                  <span className="mt-2">
                    <img src={alanaji} alt="alanaji" />
                  </span>
                  <p>Al Anaji Estabilishment</p>
                </li>
                {/* <li data-aos="fade-left" className="border-end-0">
                  {" "}
                  <span>
                    <img src={bootstrap} alt="bootstrap" />
                  </span>
                  <p>Bootstrap</p>
                </li> */}
                <li data-aos="fade-right" className=" border-bottom-0">
                  {" "}
                  <span className="mt-2">
                    <img src={muktodhara} alt="muktodhara" />
                  </span>
                  <p>Muktodhara Green Park</p>
                </li>
                <li data-aos="fade-right" className=" border-bottom-0">
                  <span className="mt-2">
                    <img src={challenger} alt="challenger" />
                  </span>
                  <p>Challenger Travels & Tours Ltd.</p>
                </li>
                <li data-aos="fade-right" className=" border-bottom-0">
                  {" "}
                  <span className="mt-2">
                    <img src={haidory_trade} alt="haidory trade" />
                  </span>
                  <p>Haidory Trade International</p>
                </li>
                <li
                  data-aos="fade-right"
                  className="border-end-0 border-bottom-0"
                >
                  {" "}
                  <span className="mt-2">
                    <img src={kaniz_travels} alt="kaniz travels" />
                  </span>
                  <p>Kaniz Travels</p>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="all-company-feature mt-4 pt-5">
        <Container>
          <Row>
            <Col md={12} className="text-start mb-4">
              {/* <h4>INDUSTRY EXPERTISE </h4> */}
              <h2 className="custom-head">OUR INDUSTRY EXPERIENCE</h2>
            </Col>
          </Row>
          <Row>
            <OwlCarousel
              className="owl-theme slider-custom custom-industry"
              {...options}
              loop
              margin={20}
              nav
            >
              <div class="item">
                <div className="company-slide bg1">
                  <div className="slider-icon">
                    {" "}
                    <img src={healthcare} alt="healthcare" />{" "}
                  </div>
                  <h5>Healthcare</h5>
                </div>
              </div>
              <div class="item">
                <div className="company-slide">
                  <div className="slider-icon">
                    {" "}
                    <img src={manufacturing} alt="manufacturing" />{" "}
                  </div>
                  <h5>Recruiting & Manpower</h5>
                </div>
              </div>
              <div class="item">
                <div className="company-slide bg2">
                  <div className="slider-icon">
                    {" "}
                    <img src={aviation} alt="Education" />{" "}
                  </div>
                  <h5>Study Abroad & Education</h5>
                </div>
              </div>
              <div class="item">
                <div className="company-slide bg3">
                  <div className="slider-icon">
                    {" "}
                    <img src={logistics} alt="logistics" />{" "}
                  </div>
                  <h5>Real State</h5>
                </div>
              </div>
              {/* <div class="item">
                <div className="company-slide">
                  <div className="slider-icon">
                    {" "}
                    <img src={manufacturing} alt="Recruiting" />{" "}
                  </div>
                  <h5>Recruiting & Manpower</h5>
                </div>
              </div> */}
            </OwlCarousel>
          </Row>
        </Container>
      </section>

      <section className="all-company-feature pt-2">
        <Container>
          <Row>
            <Col md={6} className="mb-4" data-aos="fade-right">
              <h2 className="mt-5 pt-4 custom-head">OUR HISTORY </h2>

              <p>
                At Swift IT, we believe in the power of technology to transform
                businesses. As a software company specializing in IT solutions
                and services, we help businesses of all sizes leverage
                technology to improve their operations and increase their
                efficiency.
                <br />
                Since our inception, we have been committed to providing our
                clients with the best possible software solutions and services.
                We understand that every business is unique, and we work closely
                with our clients to understand their specific needs and
                requirements. This allows us to develop customized software
                solutions that are tailored to the unique needs of each
                business.
              </p>

              <Col md={12} className="mt-4">
                <NavLink exact to="/about-us">
                  <Button variant="primary btn-custom rounded mt-3 btn-big">
                    Read Details
                    <FaArrowRight className="ms-5" />
                  </Button>
                </NavLink>
              </Col>
            </Col>
            <Col md={6} className="mt-5 mb-4" data-aos="fade-left">
              <img className="abouimg mt-4" src={about} alt="about" />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="all-company-feature frame-bg mtp" id="technologies">
        <Container>
          <Row>
            <Col md={12} className="text-center">
              <h2 className="custom-head">OUR TECHNOLOGIES</h2>
              {/* <h2>Technologies</h2> */}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ul className="ul-tech-list">
                <li data-aos="fade-right">
                  {" "}
                  <span>
                    <img src={reacjs} alt="reacjs" />
                  </span>
                  <p>Reactjs</p>
                </li>
                <li data-aos="fade-right">
                  <span>
                    <img src={angular} alt="angular" />
                  </span>
                  <p>Angularjs</p>
                </li>
                <li data-aos="fade-right" className="">
                  {" "}
                  <span>
                    <img src={node} alt="node" />
                  </span>
                  <p>Node.js</p>
                </li>
                <li data-aos="fade-right" className="">
                  {" "}
                  <span>
                    <img src={java} alt="java" />
                  </span>
                  <p>Java</p>
                </li>
                <li data-aos="fade-right" className="border-end-0">
                  {" "}
                  <span>
                    <img src={spring} alt="spring" />
                  </span>
                  <p>Spring Boot</p>
                </li>
                <li data-aos="fade-right" className="border-bottom-0">
                  {" "}
                  <span>
                    <img src={pay} alt="pay" />
                  </span>
                  <p>Python</p>
                </li>

                <li data-aos="fade-left" className="border-bottom-0">
                  {" "}
                  <span>
                    <img src={php} alt="php" />
                  </span>
                  <p>PHP</p>
                </li>

                <li data-aos="fade-left" className="border-bottom-0">
                  {" "}
                  <span>
                    <img src={lara} alt="lara" />
                  </span>
                  <p>Laravel</p>
                </li>
                <li data-aos="fade-left" className="border-bottom-0">
                  {" "}
                  <span>
                    <img src={wp} alt="wordpress" />
                  </span>
                  <p>Wordpress</p>
                </li>
                <li
                  data-aos="fade-left"
                  className="border-bottom-0 border-end-0"
                >
                  {" "}
                  <span>
                    <img src={bootstrap} alt="bootstrap" />
                  </span>
                  <p>Bootstrap</p>
                </li>
                {/* <li
                  data-aos="fade-right"
                  className="border-bottom-0 border-top-do"
                >
                  {" "}
                  <span>
                    <img src={codin} alt="codin" />
                  </span>
                  <p>Codeignitor</p>
                </li>
                <li
                  data-aos="fade-right"
                  className="border-bottom-0 border-top-do"
                >
                  {" "}
                  <span>
                    <img src={ios} alt="ios" />
                  </span>
                  <p>iOS</p>
                </li>
                <li
                  data-aos="fade-right"
                  className="border-bottom-0 border-top-do"
                >
                  {" "}
                  <span>
                    <img src={appd} alt="appd" />
                  </span>
                  <p>Android</p>
                </li>
                <li
                  data-aos="fade-right"
                  className="border-bottom-0 border-top-do"
                >
                  {" "}
                  <span>
                    <img src={bootstrap} alt="bootstrap" />
                  </span>
                  <p>Bootstrap</p>
                </li>
                <li
                  data-aos="fade-right"
                  className="border-bottom-0 border-end-0 border-top-do"
                >
                  {" "}
                  <span>
                    <img src={ember} alt="ember" />
                  </span>
                  <p>Ember</p>
                </li> */}
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <section className="all-company-feature bg-deep-gray bg-deep-gray2 mt-3 pt-3 certification-slide">
        <Container>
          <Row>
            <Col md={6} className="mt-5 mb-4 pb-5 pb-res" data-aos="flip-up">
              <h2 className="mt-5">Our Clients</h2>
              <p>
                {" "}
                We keep deepening the expertise to meet your highest
                expectations and <br />
                build even more innovative software.{" "}
              </p>
            </Col>
            <Col md={6} className="mt-4 mb-4">
              <OwlCarousel
                className="owl-theme slider-custom slider-custom-two slide-img2"
                {...options3}
                loop
                margin={20}
                nav
              >
                <div class="item">
                  <div className="company-slide">
                    <div className="slider-icon2">
                      {" "}
                      <img src={awslogo} alt="awslogo" />{" "}
                      
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div className="company-slide">
                    <div className="slider-icon2">
                      {" "}
                      <img src={googlepartner} alt="googlepartner" />{" "}
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div className="company-slide">
                    <div className="slider-icon2">
                      {" "}
                      <img src={mslogo} alt="mslogo" />{" "}
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div className="company-slide">
                    <div className="slider-icon2">
                      {" "}
                      <img src={awslogo} alt="awslogo" />{" "}
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div className="company-slide">
                    <div className="slider-icon2">
                      {" "}
                      <img src={googlepartner} alt="googlepartner" />{" "}
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div className="company-slide">
                    <div className="slider-icon2">
                      {" "}
                      <img src={mslogo} alt="mslogo" />{" "}
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </Col>
          </Row>
        </Container>
      </section> */}
      {/* <section className="all-company-feature pt-3">
        <Container>
          <Row>
            <Col md={6} className="mt-4 mb-4" data-aos="fade-right">
              <div className="company-block-feature-two">
                {" "}
                <img src={leftimg} alt="leftimg" />{" "}
              </div>
            </Col>
            <Col md={6} className="mt-4 mb-4" data-aos="fade-left">
              <h4 className="mt-2 pt-4">WHO WE ARE </h4>
              <h2>
                Offshore Software
                <br />
                DevelopmentCompany In USA
              </h2>
              <p>
                {" "}
                We Swift IT Solutions is an offshore development company in USA
                that you can trust for quality and reliability. We have been
                delivering high quality, scalable and cost effective offshore
                software development and web solutions to a global clientele
                since 2005 with full professionalism, skill and expertise to
                bring smile on faces of our clients by providing best ROI to
                them in time. When we work for you we ensure that your goals and
                objectives are in our top priority. As a reputed software
                outsourcing company in USA, we strategize, develop and execute
                all our tasks with sensibility and perfection. Our team of
                professionals will provide you everything needed to make a
                successful product delivered to you. When you work with us, your
                goals and objectives are in the hands of experts who will help
                you strategize, develop and execute your plans perfectly. With a
                focus on providing custom offshore software development and web
                application development we are working on all the platforms and
                are up to date on the latest technology that will surpass your
                expectations. We design and develop Software solutions for our
                customers. we share our knowledge and expertise of our team of
                experts who can provide everything to make your business
                successful and grow together with you. We provide software
                development services to many of our clients spread across the
                globe and have gained high reputation in offshore software
                development industry. Hire software developers from the pool of
                our professional for a very affordable cost and get the feel of
                interaction and support of being your own team.{" "}
              </p>
              <Col md={12} className="mt-4">
        <Button variant="primary btn-custom mt-3 btn-big">Read Details
        <FaArrowRight className="ms-5" />
        </Button>
        </Col>
            </Col>
          </Row>
        </Container>
      </section> */}
      <Col md={12} className="mt-4">
        <hr className="hr" />
      </Col>
      <section className="all-company-feature pt-3 mb-5">
        <Container>
          <Row className="mt-5">
            <Col md={6} className="mt-5 my-2">
              <div className="company-slide " data-aos="flip-left">
                <Row>
                  <Col md={12} className="">
                    <h2>Our Vision</h2>
                    <p>
                      To be a leading software development company recognized
                      for delevering innovative and high-quality solutions that
                      empower businessess to achieve their goals and enhance
                      their efficiency in an increasingly digital world.
                    </p>

                    <h2>Our Mission</h2>
                    <p>
                      As Swift IT Ltd., our misssion is to provide exceptional
                      software development services that meet the unique needs
                      of our clients. We are committed to leveraging the latest
                      technologies and industry best practices to deliver
                      scalable, reliable and user-friendly software solutions.
                      Through collaboration, continuous improvement and a
                      customer-centric approach, we aim to foster long-term
                      partnerships with our clients, helping them to navigate
                      the complexities of technology and drive their success.
                    </p>
                  </Col>
                </Row>
              </div>
              {/* <div
                className="company-slide mission-vission mt-4"
                data-aos="flip-right"
              >
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">Our Mission</h5>
                    <p className="mb-0">
                      {" "}
                      SAcross the globe, the Enterprise ecosystem is evolving.
                      This has created a new scenario for businesses operating
                      in the market. You either have robus…{" "}
                    </p>
                    <NavLink exact to="/mission">
                      Read more...
                    </NavLink>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon">
                      {" "}
                      <img src={bs} alt="bs" />{" "}
                    </div>
                  </Col>
                </Row>
              </div> */}
            </Col>
            <Col md={6} className="my-2" data-aos="fade-left">
              <div className="company-block-feature-two position-img">
                {" "}
                <img src={rightimfe} alt="rightimfe" />{" "}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <section className="all-company-feature mtp">
        <Container>
          <Row>
            <Col md={5} data-aos="fade-right">
              <img className="img-client mt-4" src={client} alt="hire" />
            </Col>
            <Col md={7} data-aos="fade-left">
              <Row>
                <Col md={12}>
                  <h4>CLIENTS' FEEDBACK</h4>
                  <h2>Testimonials</h2>
                </Col>
              </Row>
              <Carousel
                fade
                className="slider-custom slider-custom-two display-none-slide"
              >
                <Carousel.Item>
                  <Row>
                    <Col md={12}>
                      <div className="client-block">
                        <div className="client-top">
                          {" "}
                          <img
                            src={avater}
                            alt="avater"
                            className="client-av"
                          />
                          <div className="client-details">
                            <h6 className="mb-1">Richard Roth</h6>
                            <label> United States </label>
                          </div>
                        </div>
                        <p className="detail-message">
                          {" "}
                          We have been working with Swift IT Solutions USA Pvt
                          Ltd from last year and now for a very complicated
                          project that we are in, with lot of software
                          technologies involved. Swift IT Solutions USA has
                          matched up to our expectations and the deliverables
                          were in time.{" "}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Carousel.Item>
                <Carousel.Item>
                  <Row>
                    <Col md={12}>
                      <div className="client-block">
                        <div className="client-top">
                          {" "}
                          <img
                            src={avater}
                            alt="avater"
                            className="client-av"
                          />
                          <div className="client-details">
                            <h6 className="mb-1">Swan Atkins</h6>
                            <label> United States </label>
                          </div>
                        </div>
                        <p className="detail-message">
                          The Swift IT team has always promptly returned my
                          calls or emails no matter what the time is. I've found
                          them to be consistently available and committed to
                          addressing my needs. I've already recommended them
                          many times and I will continue to do so.{" "}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Carousel.Item>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </section> */}

      {/* <section className="all-company-feature bg-deep-gray mt-5 pt-5">
        <Container>
          <Row>
            <Col md={12} className="text-start mt-4 mb-4">
              <h2 className="custom-head">Our Recent Works</h2>
            </Col>
          </Row>
          <Row>
            <OwlCarousel
              className="owl-theme slider-custom"
              {...options2}
              loop
              margin={20}
              nav
            >
              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={worka}
                    alt="worka"
                  />{" "}
                </div>
              </div>
              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={workb}
                    alt="workb"
                  />{" "}
                </div>
              </div>
              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={workc}
                    alt="workc"
                  />{" "}
                </div>
              </div>
              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={workd}
                    alt="workd"
                  />{" "}
                </div>
              </div>

              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={worke}
                    alt="worke"
                  />{" "}
                </div>
              </div>
              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={workf}
                    alt="workf"
                  />{" "}
                </div>
              </div>
            </OwlCarousel>
          </Row>
        </Container>
      </section> */}

      <section className="all-company-feature mtp">
        <Container className="bg-gray">
          <Row>
            <Col md={6} className="schedu-bg p-0" data-aos="fade-down-right">
              <div className="block-schedue">
                <h6 className="text-white">SCHEDULE MEETING</h6>
                <h5 className="text-white mt-4">
                  Schedule A Custom 20 Min Consultation
                </h5>
                <p className="text-white mt-1">
                  {" "}
                  Contact us today to schedule a free, 20-minute call to learn
                  how Swift IT Ltd can help you revolutionize the way your
                  company conducts business.{" "}
                </p>
              </div>
            </Col>
            <Col md={6} className="form-area" data-aos="fade-down-left">
              <Form>
                <Row className="mb-3 me-0 sch-block">
                  <h3 className="h3-schedule mb-4">Schedule Meeting</h3>
                  <Form.Group as={Col} className="ps-0">
                    <Form.Label>Date</Form.Label>
                    <Form.Control type="date" placeholder="MM/DD/YYYY" />
                  </Form.Group>
                  <Form.Group as={Col} className="pe-0 spaceres">
                    <Form.Label>Time</Form.Label>
                    <Form.Control type="time" placeholder="HH:MM AM/PM" />
                  </Form.Group>
                </Row>
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control placeholder="" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" placeholder="" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Contact number</Form.Label>
                  <Form.Control type="text" placeholder="" />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Message</Form.Label>
                  <Form.Control as="textarea" className="textform" rows={3} />
                </Form.Group>
                <Col md={12} className="text-center mt-4">
                  <Button variant="primary btn-custom rounded mt-3 btn-big">
                    Submit
                    <FaArrowRight className="ms-5" />
                  </Button>
                </Col>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <div className="clearfix"></div> */}
      {/* <Container>
        <div className="comapny-status">
          <Row>
            <Col md={12} className="text-center">
              <div className="company-status-block">
                <img src={partners} alt="partners" />
                <h3>12+</h3>
                <p>Tech Partners</p>
              </div>

              <div className="company-status-block">
                <img src={experience} alt="experience" />
                <h3>10+</h3>
                <p>Years of Experience</p>
              </div>

              <div className="company-status-block">
                <img src={countires} alt="countires" />
                <h3>15+</h3>
                <p>Countries Served</p>
              </div>

              <div className="company-status-block">
                <img src={brain} alt="brain" />
                <h3>100+</h3>
                <p>IT Professionals</p>
              </div>
              <div className="company-status-block">
                <img src={projectc} alt="projectc" />
                <h3>500+</h3>
                <p>Projects Completed</p>
              </div>
            </Col>
          </Row>
        </div>
      </Container> */}

      {/* <section className="all-company-feature mtp">
    <Container>
      <Row>
        <Col md={12} className="text-center">
        <h4>LATEST BLOGS</h4>
        <h2>Latest From Our Blogs</h2>
        </Col>
      </Row>
      <Row className='mt-4 pt-3'>
        <Col md={4} data-aos="zoom-in">
        <div className='block-list'>
          <div className='block-banner'> <img src={block0} alt="block0" />
            <div className='block-status'>
              <Row>
                <Col md={6}>
                <FaCalendarAlt/>
                Jule 26, 2022
                </Col>
                <Col md={6}>
                <FaCommentDots/>
                Comments (23)
                </Col>
              </Row>
            </div>
          </div>
          <div className='p-4'>
            <h3 className='mt-3 mb-1'>How to Create a Responsive Website Using Full-Stack Development</h3>
            <div className='client-top mt-4'> <img src={clientimg} alt="clientimg" className='client-av' />
              <div className='client-details width70'>
                <label> <i>Post By</i> </label>
                <h6 className='mb-1'>Jone Doe</h6>
              </div>
            </div>
            <div className='clearfix'></div>
            <Col md={12} className="mt-4">
            <p>The need for outsourced software development is growing along with the relevance of software development to businesses. </p>
            <NavLink exact to="">Read more...</NavLink>
            </Col>
          </div>
        </div>
        </Col>
        <Col md={4} data-aos="zoom-in">
        <div className='block-list'>
          <div className='block-banner'> <img src={block1} alt="block1" />
            <div className='block-status'>
              <Row>
                <Col md={6}>
                <FaCalendarAlt/>
                Jule 26, 2022
                </Col>
                <Col md={6}>
                <FaCommentDots/>
                Comments (23)
                </Col>
              </Row>
            </div>
          </div>
          <div className='p-4'>
            <h3 className='mt-3 mb-1'>Why ReactJS is a Better Choice for Enterprise App Development</h3>
            <div className='client-top mt-4'> <img src={clientimg2} alt="clientimg2" className='client-av' />
              <div className='client-details width70'>
                <label> <i>Post By</i> </label>
                <h6 className='mb-1'>Najir Sams</h6>
              </div>
            </div>
            <div className='clearfix'></div>
            <Col md={12} className="mt-4">
            <p>The development of an enterprise application that precisely matches your company's needs might drive growth overall tremendously. </p>
            <NavLink exact to="">Read more...</NavLink>
            </Col>
          </div>
        </div>
        </Col>
        <Col md={4} data-aos="zoom-in">
        <div className='block-list'>
          <div className='block-banner'> <img src={block2} alt="block2" />
            <div className='block-status'>
              <Row>
                <Col md={6}>
                <FaCalendarAlt/>
                Jule 26, 2022
                </Col>
                <Col md={6}>
                <FaCommentDots/>
                Comments (23)
                </Col>
              </Row>
            </div>
          </div>
          <div className='p-4'>
            <h3 className='mt-3 mb-1'>Flutter Or React Native? Which Is More Advantageous</h3>
            <div className='client-top mt-4'> <img src={user3} alt="user3" className='client-av' />
              <div className='client-details width70'>
                <label> <i>Post By</i> </label>
                <h6 className='mb-1'>Thomash Jary</h6>
              </div>
            </div>
            <div className='clearfix'></div>
            <Col md={12} className="mt-4">
            <p> The market for mobile app development is expanding quickly, especially due to COVID-19's need for specialized lifestyle and </p>
            <NavLink exact to="">Read more...</NavLink>
            </Col>
          </div>
        </div>
        </Col>
        <Col md={12} className="mt-4 text-center mt-4">
        <Button variant="primary btn-custom mt-3 btn-big">See All
        <FaArrowRight className="ms-5" />
        </Button>
        </Col>
      </Row>
    </Container>
  </section> */}
    </Common>
  );
};

export default Home;
